import React, { useContext } from 'react';
import Layout from '../../components/Layout';
import Hero2, { talkToDoctorUF } from '../../components/Hero/Hero2';

import Bleeding from '../../assets/svgs/Bleeding.svg';
import KnockoutDriver from '../../components/KnockoutDriver/KnockoutDriver';
import ConvoStarted, {
  ConvoStartedUF
} from '../../components/ConvoStarted/ConvoStarted';
import DoctorDiscussionGuide from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuide';
import TalkToDocDark, {
  TalkToDocDarkUF
} from '../../components/TalkToDocDark/TalkToDocDark';
import { DDGUF } from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuideUF';
import DoctorDiscussionGuideToPDF, {
  DDGtoPDFUF
} from '../../components/DoctorDiscussionGuide/DoctorDiscussionGuideToPDF/DoctorDiscussionGuideToPDF';
import { DDGStateContext } from '../../contexts/DoctorDiscussionGuideContext';
import UfAtTheDoctorHero from '../../components/Hero/uf-at-the-doctor-hero';
import TalkingDoctorCalloutUf from '../../components/TalkingDoctorCalloutUf/talking-doctor-callout-uf';
import TalkingCallout from '../../components/TalkingCalloutUf/TalkingCallout';

const Home = () => {
  const DDGState = useContext(DDGStateContext);
  return (
    <Layout section="UF" page="/uterine-fibroids/at-the-doctor/">
      <>
      <UfAtTheDoctorHero />
      <TalkingDoctorCalloutUf/>


        {/* <Hero2 {...talkToDoctorUF} />
        

        <TalkToDocDark {...TalkToDocDarkUF} /> */}
        {/* <ConvoStarted {...ConvoStartedUF} /> */}
        <DoctorDiscussionGuide {...DDGUF} />
        <DoctorDiscussionGuideToPDF
          {...{
            ...DDGtoPDFUF,
            questions: DDGUF.questions,
            data: DDGState.UF,
            type: 'UF'
          }}
        />
        <TalkingCallout />
        {/* <KnockoutDriver
          {...{
            icon: <Bleeding />,
            iconStroke: 'fuchsia',
            iconBgColor: 'white',
            prompt:
              'Get a break from heavy period bleeding<br class="hidden md:block" /> with Myfembree',
            buttonProps: {
              label: 'SEE WHAT&apos;S POSSIBLE',
              ariaLabel: '',
              color: 'secondary-fuchsia',
              target: '_self',
              href: '/uterine-fibroids/why-myfembree/',
              position: 'right'
            },
            color: 'purple-100'
          }}
        /> */}
      </>
    </Layout>
  );
};

export const Head = () => {
  const canonicalURL =
    'https://www.myfembree.com/uterine-fibroids/at-the-doctor/';
  const title =
    'Talk to Your Doctor about UF | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const pageTitle =
    'Talk to Your Doctor about UF | MYFEMBREE® (relugolix, estradiol, and norethindrone acetate) 40mg, 1mg, 0.5mg Tablets';
  const description =
    'Use this guide to begin a conversation with your gynecologist about treating heavy menstrual bleeding from uterine fibroid with MYFEMBREE® during your next visit. See safety info & BOXED WARNING. ';
  return (
    <>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="title" content={pageTitle} />
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalURL} />
      <meta name="url" content={canonicalURL} />
      <html lang="en" />
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.3/html2pdf.bundle.min.js"
        type="application/javascript"
      />
      <link rel="stylesheet" href="/carousel.css" />
    </>
  );
};

export default Home;
